module.exports = {
  defaultLanguage: "pt-pt",
  languages: {
    "pt-pt": {
      key: "pt",
      shortLabel: "PT",
      label: "Português"
    },
    "en-us": {
      key: "en",
      shortLabel: "EN",
      label: "English"
    },
    "es-es": {
      key: "es",
      shortLabel: "ES",
      label: "Spanish"
    }
  }
};
